import React from "react";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui icons
import Help from "@material-ui/icons/Help";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import featuresStyle from "assets/jss/material-kit-pro-react/views/pricingSections/featuresStyle.js";
import { Helmet } from "react-helmet";
import Layout from "views/Layout/Layout.js";
import city from "assets/img/svg/covidbg.svg";
import { Link } from "gatsby";
import Button from "components/CustomButtons/Button.js";
import image1 from "assets/img/svg/1.svg";
import image2 from "assets/img/svg/2.svg";
import image3 from "assets/img/svg/3.svg";

const useStyles = makeStyles(featuresStyle);

export default function SectionFaqs() {
  const classes = useStyles();
  const schemaOrgJSONLD = [
    {
      '@context': 'http://schema.org',
      '@type': 'Website',
      'name': 'COVID-19 Module',
      'description': 'BUSLOC COVID-19 Module',
      'url': 'www.busloc.io/covid',
      'keywords': 'BUSLOC, BUSLOC COVID-19 Module',
      'og:description': 'BUSLOC COVID-19 Module',
      'og:title': 'BUSLOC - COVID-19 Module'

    },
  ];
  return (
    <Layout>
      <Helmet>
        <title>BUSLOC - COVID-19 Module</title>
        <meta name="description" content="COVID-19 Module" />
        <meta name="keywords" content="COVID-19 Module" />
        {/* JSON-Ld tags*/}
        <script type="application/ld+json">
          {JSON.stringify(schemaOrgJSONLD)}
        </script>
      </Helmet>
      <br />
      <br />
      <div className={classes.featuresSection} style={{ backgroundImage: `url(${city})` }}>

        <GridContainer>
          <GridItem xs={12} sm={12} md={2} lg={2}></GridItem>
          <GridItem xs={12} sm={12} md={8} lg={8}>
            <Card raised style={{ padding: '10px' }}>
              <div className={classes.textCenter}>
                <h3 className={classes.title} style={{ fontSize: '30px' }}>BUSLOC Facilitates COVID-19 Checks For Boarding the School Bus</h3>
              </div>
            {/*   <div style={{ textAlign: 'center' }}>
                <Button round size="sm" color="rose" component={Link} to="/faq/#general" style={{ fontWeight: 700 }} href="https://cp.busloc.io/auth/signup">
                  General
                       </Button>
               
              </div> */}
              <br />
              <Card >



                <CardBody>
                  <p className={classes.descriptionp} style={{ fontSize: '18px' }}>Schools reopening for the 2020–2021 academic year has been a delicate and contentious subject. 
                  With the coronavirus still active in the US, parents, teachers, school administrators, community leaders, and politicians are still debating the steps to implement to keep the students safe from exposure to the virus. As part of their guidelines for schools and bus operators, CDC (Center for Disease Control and Prevention) recommends regular screening of staff and students for high temperatures, coughing, and other COVID-19 symptoms. Concomitant to the CDC guidelines, state education and health agencies are asking for similar checks before the students board the buses.</p>
                  
<br/>
                  <p className={classes.descriptionp}>BUSLOC software now facilitates daily COVID-19 checks for students prior to boarding the school bus. 
                  The software is FREE for the staff, parents, and attendants/drivers to use.</p><br/>
                  <p className={classes.descriptionp}>The software module (Parents app and Driver/Attendant app) allows</p>
                  <ul>

                      <li style={{fontWeight:"bold"}}>
                      parents to attest the daily health status of the student and
                      </li>
                      <li style={{fontWeight:"bold"}}>
                      the bus attendant to perform the checks at the bus stop prior to boarding.
                      </li>
                  </ul>

                  <p className={classes.descriptionp}>School District Administrators can opt (in the Desktop version) for the steps to be included in allowing the student to board the bus. Schools and District Administrators can also view the daily activity reports; they can use these reports to inform any positive cases to the local health officials for contact tracing. 
                  Send an email to support@busloc.io for setting up a quick demo of the COVID-19 module for your school district.</p>
                  <br/>
                  <p className={classes.descriptionp}>BUSLOC will soon be adding a Contact Tracing module to the software for the school staff and parents that allows the identification of any virus hot spots and help the school administrators isolate the hot spots to keep the school environment safe of the virus.</p>
                </CardBody>

              </Card>
             



               

     </Card>
          </GridItem>
      </GridContainer>

            
     
         
    </div>
    </Layout>
        );
      }
